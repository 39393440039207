<template>
  <div class="index">
    <ul class="menu">
      <li @click="openMenu('account')">我的资料</li>
      <li @click="openMenu('accountTruthName')">实名认证</li>
      <li @click="openMenu('accountAddress')">我的地址</li>
      <li @click="openMenu('accountOrder')">我的订单</li>
      <li @click="openMenu('accountCart')">我的购物车</li>
      <li @click="openMenu('accountTuijian')">我的推荐</li>
    </ul>
    <div class="main">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="我的推荐" name="first">
          <div class="first" v-if="active == 1">
            <div class="firstLink">
              我的推荐链接
              <el-input
                size="medium"
                class="linkInput"
                v-model="nextLink"
              ></el-input>
              <el-button
                type="primary"
                size="medium"
                v-clipboard:error="onError"
                v-clipboard:copy="nextLink"
                v-clipboard:success="onCopy"
                >点击复制</el-button
              >
            </div>
            <div class="firstLink">
              邀请好友加入keroo大家庭
              <el-input
                size="medium"
                v-model="invitephone"
                placeholder="请输入好友手机号"
              ></el-input>
              <el-button @click="inviteUser" type="primary" size="medium"
                >一键邀请</el-button
              >
            </div>
            <div class="tips">温馨提示</div>
            <div class="tipsText">
              <p>
                1、推荐号就是会员账号，只有注册时填写了推荐号才能建立推荐关系。已邀请用户注册时未填写推荐人则自动归属于邀请人。如果已邀请用户填写了其他推荐号或手机号，推荐关系以用户填写的为准。
              </p>
              <p>
                2、同一个手机号，只能邀请一次。系统已经存在的手机号，无法邀请。若邀请30天后，
                客户未确认邀请，则取消其预定的绑定关系。可以再次邀请，以此类推。
              </p>
              <p>
                3、可柔科技将采用大数据技术杜绝违规操作及作弊行为（包括但不限于：
                恶意注册虚假账号、恶意刷奖等），若判定为违规操作或作弊行为，
                可柔科技有权取消其资格。对于以上内容中有争议的部分以公司的解释为准。
              </p>
            </div>
            <div
              style="
                line-height: 50px;
                padding-left: 15px;
                background: pink;
                font-size: 16px;
                margin: 15px 0;
              "
            >
              推荐详情
            </div>
            <div class="firstLink">
              <span>累计获得奖励金总额</span>
              <el-input
                size="medium"
                readonly=""
                v-model="brokerageTotalPrice"
                class="counts"
              ></el-input>
              <span>奖励金余额</span>
              <el-input
                size="medium"
                readonly=""
                v-model="userInfo.brokeragePrice"
                class="counts"
              ></el-input>
              <span>已使用奖励金</span>
              <el-input
                size="medium"
                readonly=""
                v-model="usedMoney"
                class="counts"
              ></el-input>
            </div>
            <div class="firstLink">
              <span>推荐奖励金总额</span>
              <el-input
                size="medium"
                v-model="userInfo.brokerageRecommendPrice"
                class="counts"
              ></el-input>
              <span>提成奖励金总额</span>
              <el-input
                size="medium"
                v-model="userInfo.brokerageOnePrice"
                class="counts"
              ></el-input>
              <span>返点奖励金总额</span>
              <el-input
                size="medium"
                v-model="userInfo.brokerageTwoPrice"
                class="counts"
              ></el-input>
            </div>
            <div class="firstLink">
              <span>已推荐会员数</span>
              <el-input
                size="medium"
                v-model="userInfo.spreadCount"
                class="counts"
              ></el-input>
              <span>已邀请待确认数</span>
              <el-input
                size="medium"
                v-model="userInfo.toBeConfirmedNum"
                class="counts"
              ></el-input>
            </div>
            <div class="firstLink">
              <span>二级推荐会员数</span>
              <el-input
                size="medium"
                v-model="userInfo.twoRecommendNum"
                class="counts"
              ></el-input>
              <span>返点奖励金</span>
              <el-date-picker
                style="width: 120px"
                v-model="paybackMonth"
                type="month"
                placeholder="选择月"
              >
              </el-date-picker>
              为
              <el-input
                size="medium"
                v-model="paybackMonthPrice"
                class="counts"
              ></el-input>
            </div>
            <div class="tips">温馨提示</div>
            <div class="tipsText">
              <p>
                1、推荐招募奖励金需被推荐人签收一笔订单后方可发放。推荐奖和招募奖只奖励被推荐人第一次升级后的级别奖励。后续再升级级别的不再奖励。奖励金冻结期限为7天。
              </p>
              <p>
                2、提成奖励金以订单签收为准。若等待期间订单发生退款退货，则该笔奖励金失效。奖励金冻结期限为7天。
              </p>
              <p>
                3、返点奖励金为大王级别独有。其他级别暂不开启返点奖励。升到大王级别自动开启。奖励金冻结期限为7天。
              </p>
              <p>
                4、奖励金可用于购物抵扣支付，奖励金余额大于一千元可申请提现。提现平台将收取提现金额的15%服务费。
              </p>
              <p>5、若申请退出代理时还有奖励金余额，则视为账户余额一并提现。</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="推荐招募详情" name="second">
          <div class="thred">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column
                prop="userAccount"
                label="被推荐人账号"
                width="180"
              >
              </el-table-column>
              <el-table-column
                prop="userLevel"
                label="被推荐人级别"
                width="180"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.userLevel == 1">游客</span>
                  <span v-else-if="scope.row.userLevel == 2">达人</span>
                  <span v-else-if="scope.row.userLevel == 3">大户</span>
                  <span v-else-if="scope.row.userLevel == 4">大咖</span>
                  <span v-else-if="scope.row.userLevel == 5">大王</span>
                </template>
              </el-table-column>
              <el-table-column prop="brokeragePrice" label="推荐奖金额">
              </el-table-column>
              <el-table-column prop="brokerageStatus" label="推荐奖状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.brokerageStatus == 1">已发放</span>
                  <span v-else>未发放</span>
                </template>
              </el-table-column>
              <el-table-column prop="distributeTime" label="奖励时间">
                <template slot-scope="scope">
                  <span v-if="!scope.row.distributeTime">未奖励</span>
                  <span v-else>{{ scope.row.distributeTime }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="description" label="奖励说明">
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="提成奖励金详情" name="third">
          <div class="second">
            <el-table :data="spreadPeopleList" style="width: 100%">
              <el-table-column prop="linkId" label="已完成订单号" width="220px">
              </el-table-column>
              <el-table-column prop="userAccount" label="被推荐人账号">
              </el-table-column>
              <el-table-column prop="userLevel" label="被推荐人级别">
                <template slot-scope="scope">
                  <span v-if="scope.row.userLevel == 1">游客</span>
                  <span v-else-if="scope.row.userLevel == 2">达人</span>
                  <span v-else-if="scope.row.userLevel == 3">大户</span>
                  <span v-else-if="scope.row.userLevel == 4">大咖</span>
                  <span v-else-if="scope.row.userLevel == 5">大王</span>
                </template>
              </el-table-column>
              <el-table-column prop="price" label="奖励金额"> </el-table-column>
              <el-table-column prop="updateTime" label="奖励时间">
                <template slot-scope="scope">
                  <span v-if="!scope.row.updateTime">未奖励</span>
                  <span v-else>{{ scope.row.updateTime }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="返点奖励金详情" name="fourth">
          <div class="fouth">
            <el-table :data="tableData1" style="width: 100%">
              <el-table-column prop="orderId" label="已完成订单号" width="280">
              </el-table-column>
              <el-table-column
                prop="userAccount"
                label="被推荐人账号"
                width="180"
              >
              </el-table-column>
              <el-table-column prop="userLevel" label="被推荐人级别">
                 <template slot-scope="scope">
                  <span v-if="scope.row.userLevel == 1">游客</span>
                  <span v-else-if="scope.row.userLevel == 2">达人</span>
                  <span v-else-if="scope.row.userLevel == 3">大户</span>
                  <span v-else-if="scope.row.userLevel == 4">大咖</span>
                  <span v-else-if="scope.row.userLevel == 5">大王</span>
                </template>
              </el-table-column>
              <el-table-column prop="price" label="订单金额"> </el-table-column>
              <el-table-column prop="updateTime" label="奖励时间">
                <template slot-scope="scope">
                  <span v-if="!scope.row.updateTime">未奖励</span>
                  <span v-else>{{ scope.row.updateTime }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-pagination
        v-if="activeName != 'first'"
        style="padding-top: 15px; text-align: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { aoiGetUserinfo } from "@/api/login";
export default {
  name: "basePAge",
  data() {
    return {
      activeName: "first",
      userInfo: {},
      nextLink: "",
      active: 1,
      tableData: [],
      tableData1: [],
      invitephone: "",

      spreadPeopleList: [],
      paybackMonth: "",
      paybackMonthPrice: 0,
      limit: 20,
      page: 1,
      total: 0, //一级推荐人人数
    };
  },
  computed: {
    usedMoney() {
      return (
        this.userInfo.brokerageRecommendPrice*1 +this.userInfo.brokerageOnePrice*1 +this.userInfo.brokerageTwoPrice*1 - 1 * this.userInfo.brokeragePrice
      );
    },
    brokerageTotalPrice(){
      return  this.userInfo.brokerageRecommendPrice*1 +this.userInfo.brokerageOnePrice*1 +this.userInfo.brokerageTwoPrice*1 
    }
  },

  mounted() {
    this.getTJList();
    this.getPrizeList();
    this.getBrokerageList();
    this.getBackpayDetail();
    this.getUserInfo();
  },
  methods: {
    handleClick(val) {
      console.log(val);
      this.activeName = val.name;
    },
    handleSizeChange(val) {
      this.page = 1;
      this.limit = val;
      this.handle(this.activeName);
    },
    handleCurrentChange(val) {
      this.limit = 20;
      this.page = val;
      this.handle(this.activeName);
    },
    //获取返点奖励金详情
    getBackpayDetail() {
      this.https.get("/api/front/brokerage/twoLevelDetail").then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          if (res.data.data.length == 0) {
            this.paybackMonth = "";
            this.paybackMonthPrice = 0;
          }
        } else {
          this.paybackMonth = "";
          this.paybackMonthPrice = 0;
        }
      });
    },
    //获取用户信息
    getUserInfo() {
      aoiGetUserinfo().then((res) => {
        if (res.code == 200) {
          this.userInfo = res.data;
          this.nextLink =
            "https://mykeroo.com/#/register?" + this.userInfo.userEncryptionId;
        }
      });
    },
    onCopy() {
      this.$message({
        message: "已复制成功！",
        type: "success",
      });
    },
    // 复制失败
    onError() {
      this.$message({
        message: "复制失败！",
        type: "error",
      });
    },

    //邀请用户
    inviteUser() {
      let reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg.test(this.invitephone)) {
        this.$message({
          message: "请输入正确的手机号",
        });
      } else {
        this.https
          .post("/api/front/user/inviteUser", { phone: this.invitephone })
          .then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "已邀请，待确认",
              });
            } else {
              this.$message({
                type: "warning",
                message: res.data.message,
              });
            }
          });
      }
    },
    openMenu(index) {
      localStorage.setItem("accountIndex", "accountTuijian");
      this.$router.push({ name: index });
    },
    handle() {
      if (this.activeName == "second") {
        this.getTJList();
      } else if (this.activeName == "third") {
        this.getPrizeList();
      } else if (this.activeName == "seconfourthd") {
        this.getBrokerageList();
      }
    },

    //推荐招募详情
    getTJList() {
      this.https
        .get(
          "/api/front/spread/recommendList?page=" +
            this.page +
            "&limit=" +
            this.limit
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.total = res.data.data.total;
            this.tableData = res.data.data.list;
          } else {
            this.tableData = [];
            this.total = 0;
          }
          console.log(this.tableData);
        });
    },
    //提成奖励金详情
    getPrizeList() {
      // /api/front/brokerage/list
      this.https
        .get(
          "/api/front/brokerage/list?page=" + this.page + "&limit=" + this.limit
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.total = res.data.data.total;
            this.spreadPeopleList = res.data.data.list;
          } else {
            this.spreadPeopleList = [];
            this.total = 0;
          }
          console.log(this.spreadPeopleList);
        });
    },
    //返点奖励金详情
    getBrokerageList() {
      // /api/front/brokerage/twoLevelList
      this.https
        .get(
          "/api/front/brokerage/twoLevelList?page=" +
            this.page +
            "&limit=" +
            this.limit
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.total = res.data.data.total;
            this.tableData1 = res.data.data.list;
          } else {
            this.tableData1 = [];
            this.total = 0;
          }
          console.log(this.tableData1);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f6f7;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  .menu {
    width: 200px;
    background-color: #fff;
    li {
      line-height: 60px;
      border-bottom: 1px solid #ccc;
      font-size: 16px;
    }
  }
  .main {
    width: 980px;
    background-color: #fff;
    padding: 15px 20px;
    text-align: left;
    .title {
      line-height: 50px;
      text-align: left;
      font-size: 16px;
      border-bottom: 1px dashed blue;
      margin-bottom: 20px;
      .el-col {
        cursor: pointer;
      }
    }
    .firstLink {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      line-height: 50px;
      .el-input {
        width: 370px;
        margin: 0 15px;
      }
      .linkInput {
        width: 447px;
      }
      span {
        width: 140px;
      }
      .counts {
        width: 120px;
      }
    }
    .tips {
      line-height: 40px;
      font-size: 16px;
    }
    .tipsText p {
      line-height: 30px;
    }
  }
}
.activedTitle {
  color: blue;
}
::v-deep.el-tabs__nav {
  width: 100%;
  .el-tabs__item {
    width: 24%;
    text-align: center;
  }
}
</style>